import React, { createContext, useContext, useState, useEffect } from 'react';

// Create AuthContext
const AuthContext = createContext({
    user: null,
    setUser: () => { },
    logout: () => { },
});

// Create a hook to use AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider Component
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Loading state

    // Load user from localStorage when the app initializes
    useEffect(() => {
        try {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                setUser(JSON.parse(storedUser)); // Restore user state
            }
        } catch (error) {
            console.error('Failed to load user from localStorage:', error);
        } finally {
            setIsLoading(false); // Loading is complete
        }
    }, []);

    // Persist user state to localStorage whenever it changes
    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        }
    }, [user]);

    // Logout function
    const logout = () => {
        setUser(null);
        localStorage.removeItem('user'); // Clear the user session
    };

    return (
        <AuthContext.Provider value={{ user, setUser, logout, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
};
