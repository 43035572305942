import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

// Lazy-loaded components
const LandingPage = lazy(() => import('./components/LandingPage'));
const Dashboard = lazy(() => import('./components/Dashboard'));

function App() {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Landing Page */}
          <Route path="/" element={user ? <Navigate to="/dashboard" /> : <LandingPage />} />

          {/* Dashboard Page */}
          <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/" />} />

          {/* Fallback Route */}
          <Route path="*" element={<Navigate to={user ? "/dashboard" : "/"} />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
